export interface TsAppVersion {
    version: string;
    name: string;
    description?: string;
    versionLong?: string;
    versionDate: string;
    gitCommitHash?: string;
    gitCommitDate?: string;
    gitTag?: string;
};
export const versions: TsAppVersion = {
    version: '34.0.0',
    name: 'lingo2-conference-2',
    versionDate: '2024-09-05T08:21:10.217Z',
    gitCommitHash: 'a866d2e',
    versionLong: '34.0.0-a866d2e',
};
export default versions;
